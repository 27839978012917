<template>
  <el-container class="patroldetail container">
    <el-header class="box add bb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>巡查管理</el-breadcrumb-item>
       <el-breadcrumb-item >双随机巡查列表</el-breadcrumb-item>
       <el-breadcrumb-item>双随机巡查详情</el-breadcrumb-item>
    </el-breadcrumb>
        <div><el-button  class="back_btn" size="medium" plain @click="$router.back()">返回</el-button></div>
    </el-header>
    <el-main class="p15 ">
         <el-collapse v-model="activeNames" @change="handleChange">
       <el-collapse-item title="巡查信息" name="1">
          <el-row class="box mt20  f14 c666">
          <el-col :span="11" class="tl mb20" :offset="1">
          <span class="left">派发人：</span><span class="c333">{{list.user_name_add}}</span>
        </el-col>
         <el-col :span="11" class="tl mb20" :offset="1">
          <span class="left">巡查时间：</span><span class="c333">{{list.patrol_time}}</span>
        </el-col>
        <el-col :span="11" class="tl mb20" :offset="1">
          <span class="left">巡查人：</span><span class="c333">{{list.patrol_user_name}}</span>
        </el-col>
        <el-col :span="11" class="tl mb20" :offset="1">
          <span class="left">巡查对象：</span><span class="c333">{{list.company_name}}</span>
        </el-col>
        <el-col :span="11" class="tl mb20" :offset="1">
          <span class="left">巡查结果：</span><span v-if="list.patrol_result=='不合格'" class="red">不合格</span><span v-else-if="list.patrol_result=='合格'" class="c333">合格</span><span v-else class="c333">---</span>
        </el-col>
         <el-col  :span="11" class="tl mb20" :offset="1">
          <span class="left">备注信息：</span><span class="c333">{{list.note}}</span>
        </el-col>
     </el-row>
       </el-collapse-item>
       <!-- <el-collapse-item title="巡查内容" name="2">
      
       </el-collapse-item> -->
       <el-collapse-item title="巡查结果" name="2">
         <el-row class="box mt20  f14 c666">
          <el-col  :span="11" class="tl mb20" :offset="1">
          <span class="left">处理方式：</span><span class="c333">{{list.dispose_name}}</span>
        </el-col>
        <el-col  :span="11" class="tl mb20" :offset="1">
          <span class="left">处罚方式：</span><span class="c333">{{list.punish_name}}</span>
        </el-col>
     </el-row>
       </el-collapse-item>
       <el-collapse-item title="巡查图片" class="tl" name="3">
          <el-col :span="24" class="tl p15">
         
  <div v-if="list.patrol_imgs==null" class="block fl" >
   <el-image 
      :src="require('../../../assets/image/moren.png')"
      ></el-image>
  </div>
  <div v-else class="block fl" >
    <el-image  v-for="(fit,index) in list.patrol_imgs_list" :key="index"
      :src="user.qyurl+fit"
      :preview-src-list="[user.qyurl+fit]"
      :fit="fit.name"></el-image>  
  </div>
      </el-col>
       </el-collapse-item>
      </el-collapse>
    </el-main>
    <!-- 
      -->
  </el-container>
</template>
<script>
import axios from '../../../util/api'
import { local,session} from '../../../util/util'
export default {
  data () {
    return {
      page: {
       patrol_id:'',
      },
       list: [],
activeNames: ['1'],
      user:'',
    }
  },
   created () {
     this.user = local.get('user');
        this.page.patrol_id =session.get('params').id?String(session.get('params').id):'';
     this.init1(this.page)
  },
  methods: {
     init1 (params) {
       axios.get('/pc/patrol/single', params).then((v) => {
       this.list=v.data.patrol
      })
    },
      handleChange(){},
  }
}
</script>
<style lang="scss" scoped>
.patroldetail{
  overflow-x: hidden;
  .add{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .el-image{width: 160px; height: 160px;margin-right: 10px}
  .back_btn{background:rgba(247,248,249,1);border:1px solid rgba(217,222,225,1);}
  .patroldetail-title{height: 34px;line-height: 34px;border-left: 2px solid #788287}
  .patrol_way{padding: 5px;background-color:rgba($color: #2EC68A, $alpha: 0.2);color:#2EC68A; border-radius: 4px;}

}

</style>
